module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"やました整形外科クリニック","short_name":"やました整形外科","lang":"ja","description":"私達は思いやりを大切にする、地域の皆様に寄り添う整形外科クリニックです。安心して通院いただけるよう、スタッフ一丸となって務めてまいります。予約なしでも受診できます。山口県下関市のやました整形外科クリニック。","background_color":"#FFF","theme_color":"#FFF","display":"standalone","scope":"/","start_url":"/","icon":"static/favicon.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/maskable-icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"static/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/maskable-icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"819e0b9504c7945d3b68742f4836f2f7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
